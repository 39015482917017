/*todo make this work with in styled-components*/
.m-0 {
  margin: 0;
}
.mx-0 {
  margin-left: 0;
  margin-right: 0;
}
.my-0 {
  margin-top: 0;
  margin-bottom: 0;
}
.mt-0 {
  margin-top: 0;
}
.mr-0 {
  margin-right: 0;
}
.mb-0 {
  margin-bottom: 0;
}
.ml-0 {
  margin-left: 0;
}
.m-px {
  margin: 1px;
}
.mx-px {
  margin-left: 1px;
  margin-right: 1px;
}
.my-px {
  margin-top: 1px;
  margin-bottom: 1px;
}
.mt-px {
  margin-top: 1px;
}
.mr-px {
  margin-right: 1px;
}
.mb-px {
  margin-bottom: 1px;
}
.ml-px {
  margin-left: 1px;
}

.m-0_5 {
  margin: 0.125rem;
}
.mx-0_5 {
  margin-left: 0.125rem;
  margin-right: 0.125rem;
}
.my-0_5 {
  margin-top: 0.125rem;
  margin-bottom: 0.125rem;
}
.mt-0_5 {
  margin-top: 0.125rem;
}
.mr-0_5 {
  margin-right: 0.125rem;
}
.mb-0_5 {
  margin-bottom: 0.125rem;
}
.ml-0_5 {
  margin-left: 0.125rem;
}

.m-1 {
  margin: 0.25rem;
}
.mx-1 {
  margin-left: 0.25rem;
  margin-right: 0.25rem;
}
.my-1 {
  margin-top: 0.25rem;
  margin-bottom: 0.25rem;
}
.mt-1 {
  margin-top: 0.25rem;
}
.mr-1 {
  margin-right: 0.25rem;
}
.mb-1 {
  margin-bottom: 0.25rem;
}
.ml-1 {
  margin-left: 0.25rem;
}

.m-1_5 {
  margin: 0.375rem;
}
.mx-1_5 {
  margin-left: 0.375rem;
  margin-right: 0.375rem;
}
.my-1_5 {
  margin-top: 0.375rem;
  margin-bottom: 0.375rem;
}
.mt-1_5 {
  margin-top: 0.375rem;
}
.mr-1_5 {
  margin-right: 0.375rem;
}
.mb-1_5 {
  margin-bottom: 0.375rem;
}
.ml-1_5 {
  margin-left: 0.375rem;
}

.m-2 {
  margin: 0.5rem;
}
.mx-2 {
  margin-left: 0.5rem;
  margin-right: 0.5rem;
}
.my-2 {
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}
.mt-2 {
  margin-top: 0.5rem;
}
.mr-2 {
  margin-right: 0.5rem;
}
.mb-2 {
  margin-bottom: 0.5rem;
}
.ml-2 {
  margin-left: 0.5rem;
}

.m-2_5 {
  margin: 0.625rem;
}
.mx-2_5 {
  margin-left: 0.625rem;
  margin-right: 0.625rem;
}
.my-2_5 {
  margin-top: 0.625rem;
  margin-bottom: 0.625rem;
}
.mt-2_5 {
  margin-top: 0.625rem;
}
.mr-2_5 {
  margin-right: 0.625rem;
}
.mb-2_5 {
  margin-bottom: 0.625rem;
}
.ml-2_5 {
  margin-left: 0.625rem;
}

.m-3 {
  margin: 0.75rem;
}
.mx-3 {
  margin-left: 0.75rem;
  margin-right: 0.75rem;
}
.my-3 {
  margin-top: 0.75rem;
  margin-bottom: 0.75rem;
}
.mt-3 {
  margin-top: 0.75rem;
}
.mr-3 {
  margin-right: 0.75rem;
}
.mb-3 {
  margin-bottom: 0.75rem;
}
.ml-3 {
  margin-left: 0.75rem;
}

.m-3_5 {
  margin: 0.875rem;
}
.mx-3_5 {
  margin-left: 0.875rem;
  margin-right: 0.875rem;
}
.my-3_5 {
  margin-top: 0.875rem;
  margin-bottom: 0.875rem;
}
.mt-3_5 {
  margin-top: 0.875rem;
}
.mr-3_5 {
  margin-right: 0.875rem;
}
.mb-3_5 {
  margin-bottom: 0.875rem;
}
.ml-3_5 {
  margin-left: 0.875rem;
}

.m-4 {
  margin: 1rem;
}
.mx-4 {
  margin-left: 1rem;
  margin-right: 1rem;
}
.my-4 {
  margin-top: 1rem;
  margin-bottom: 1rem;
}
.mt-4 {
  margin-top: 1rem;
}
.mr-4 {
  margin-right: 1rem;
}
.mb-4 {
  margin-bottom: 1rem;
}
.ml-4 {
  margin-left: 1rem;
}

.m-5 {
  margin: 1.25rem;
}
.mx-5 {
  margin-left: 1.25rem;
  margin-right: 1.25rem;
}
.my-5 {
  margin-top: 1.25rem;
  margin-bottom: 1.25rem;
}
.mt-5 {
  margin-top: 1.25rem;
}
.mr-5 {
  margin-right: 1.25rem;
}
.mb-5 {
  margin-bottom: 1.25rem;
}
.ml-5 {
  margin-left: 1.25rem;
}

.m-6 {
  margin: 1.5rem;
}
.mx-6 {
  margin-left: 1.5rem;
  margin-right: 1.5rem;
}
.my-6 {
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
}
.mt-6 {
  margin-top: 1.5rem;
}
.mr-6 {
  margin-right: 1.5rem;
}
.mb-6 {
  margin-bottom: 1.5rem;
}
.ml-6 {
  margin-left: 1.5rem;
}

.m-7 {
  margin: 1.75rem;
}
.mx-7 {
  margin-left: 1.75rem;
  margin-right: 1.75rem;
}
.my-7 {
  margin-top: 1.75rem;
  margin-bottom: 1.75rem;
}
.mt-7 {
  margin-top: 1.75rem;
}
.mr-7 {
  margin-right: 1.75rem;
}
.mb-7 {
  margin-bottom: 1.75rem;
}
.ml-7 {
  margin-left: 1.75rem;
}

.m-8 {
  margin: 2rem;
}
.mx-8 {
  margin-left: 2rem;
  margin-right: 2rem;
}
.my-8 {
  margin-top: 2rem;
  margin-bottom: 2rem;
}
.mt-8 {
  margin-top: 2rem;
}
.mr-8 {
  margin-right: 2rem;
}
.mb-8 {
  margin-bottom: 2rem;
}
.ml-8 {
  margin-left: 2rem;
}

.m-9 {
  margin: 2.25rem;
}
.mx-9 {
  margin-left: 2.25rem;
  margin-right: 2.25rem;
}
.my-9 {
  margin-top: 2.25rem;
  margin-bottom: 2.25rem;
}
.mt-9 {
  margin-top: 2.25rem;
}
.mr-9 {
  margin-right: 2.25rem;
}
.mb-9 {
  margin-bottom: 2.25rem;
}
.ml-9 {
  margin-left: 2.25rem;
}

.m-10 {
  margin: 2.5rem;
}
.mx-10 {
  margin-left: 2.5rem;
  margin-right: 2.5rem;
}
.my-10 {
  margin-top: 2.5rem;
  margin-bottom: 2.5rem;
}
.mt-10 {
  margin-top: 2.5rem;
}
.mr-10 {
  margin-right: 2.5rem;
}
.mb-10 {
  margin-bottom: 2.5rem;
}
.ml-10 {
  margin-left: 2.5rem;
}

.m-11 {
  margin: 2.75rem;
}
.mx-11 {
  margin-left: 2.75rem;
  margin-right: 2.75rem;
}
.my-11 {
  margin-top: 2.75rem;
  margin-bottom: 2.75rem;
}
.mt-11 {
  margin-top: 2.75rem;
}
.mr-11 {
  margin-right: 2.75rem;
}
.mb-11 {
  margin-bottom: 2.75rem;
}
.ml-11 {
  margin-left: 2.75rem;
}

.m-12 {
  margin: 3rem;
}
.mx-12 {
  margin-left: 3rem;
  margin-right: 3rem;
}
.my-12 {
  margin-top: 3rem;
  margin-bottom: 3rem;
}
.mt-12 {
  margin-top: 3rem;
}
.mr-12 {
  margin-right: 3rem;
}
.mb-12 {
  margin-bottom: 3rem;
}
.ml-12 {
  margin-left: 3rem;
}

.m-16 {
  margin: 4rem;
}
.mx-16 {
  margin-left: 4rem;
  margin-right: 4rem;
}
.my-16 {
  margin-top: 4rem;
  margin-bottom: 4rem;
}
.mt-16 {
  margin-top: 4rem;
}
.mr-16 {
  margin-right: 4rem;
}
.mb-16 {
  margin-bottom: 4rem;
}
.ml-16 {
  margin-left: 4rem;
}

.m-38 {
  margin: 9.50rem;
}
.mx-38 {
  margin-left: 9.50rem;
  margin-right: 9.50rem;
}
.my-38 {
  margin-top: 9.50rem;
  margin-bottom: 9.50rem;
}
.mt-38 {
  margin-top: 9.50rem;
}
.mr-38 {
  margin-right: 9.50rem;
}
.mb-38 {
  margin-bottom: 9.50rem;
}
.ml-38 {
  margin-left: 9.50rem;
}


.m-auto {
  margin: auto;
}
.mx-auto {
  margin-left: auto;
  margin-right: auto;
}
.my-auto {
  margin-top: auto;
  margin-bottom: auto;
}
.mt-auto {
  margin-top: auto;
}
.mr-auto {
  margin-right: auto;
}
.mb-auto {
  margin-bottom: auto;
}
.ml-auto {
  margin-left: auto;
}

.mt-100px {
  margin-top: 100px;
}
